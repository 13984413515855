import { cn, optimizeCloudinaryImage } from "../utils";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { VariantProps } from "class-variance-authority";
import { avatar } from "./avatar.styles";

// Used for cloudinary optimization
const sizeToPixels = {
  xs: 16,
  sm: 48,
  md: 32,
  lg: 48,
  xl: 64,
  "2xl": 128,
  "3xl": 192,
} as const;

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement>, VariantProps<typeof avatar.root> {
  asChild?: boolean;
}

export function Avatar({ size, src, variant = "circle", className, alt }: AvatarProps) {
  return (
    <AvatarPrimitive.Root className={cn(avatar.root({ size, variant }), className)}>
      <AvatarPrimitive.Image
        className="h-full w-full rounded-[inherit] object-cover"
        src={optimizeCloudinaryImage(src, sizeToPixels[size ?? "md"]) ?? undefined}
        alt={alt || "Avatar"}
        title={alt || "Avatar"}
      />
      <AvatarPrimitive.Fallback className="h-full w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" />
    </AvatarPrimitive.Root>
  );
}
