import { cva } from "class-variance-authority";

const root = cva("inline-flex select-none items-center justify-center rounded-full bg-background overflow-hidden", {
  variants: {
    variant: {
      circle: "rounded-full",
      square: "rounded-lg",
    },
    size: {
      xs: "h-4 w-4",
      sm: "h-6 w-6",
      md: "h-8 w-8",
      lg: "h-12 w-12",
      xl: "h-16 w-16",
      "2xl": "h-32 w-32",
      "3xl": "h-48 w-48",
    },
  },
  defaultVariants: {
    size: "md",
    variant: "circle",
  },
});

export const avatar = { root };
